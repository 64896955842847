// extracted by mini-css-extract-plugin
export var blinkCaret = "index-module--blink-caret--8+Aym";
export var body = "index-module--body--RgbeY";
export var col1 = "index-module--col-1--gHv6m";
export var col2 = "index-module--col-2--0FkIf";
export var col3 = "index-module--col-3--jFXZz";
export var colDynamic = "index-module--col-dynamic--epd34";
export var date = "index-module--date--06kbX";
export var face = "index-module--face--0tYhN";
export var gridColumn = "index-module--grid-column--E3AGX";
export var gridRow = "index-module--grid-row--f6cWV";
export var handwriting = "index-module--handwriting--pvbPP";
export var headingRow = "index-module--heading-row--MbSS1";
export var reader = "index-module--reader--iBuAO";
export var row = "index-module--row--L95Bz";
export var shadowed = "index-module--shadowed--mcy-r";
export var tile = "index-module--tile--nRzfm";
export var typewriter = "index-module--typewriter--C1OAF";
export var typing = "index-module--typing--OU-KD";